import { useEffect, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import Search from "../Search/Search"
import OrdersLine from "../OrdersLine/OrdersLine"
import ServicesList from "../ServicesList/ServicesList"
import SearchList from "../SearchList/SearchList"
import { useTranslation } from "react-i18next"
import {
  useGetCategoriesByTechObjMutation,
  useGetCategoriesQuery,
  useGetServicesMutation,
  useGetTechObjectQuery,
} from "../../redux/api/main"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { changeServiceListLoading, updateServiceList } from "../../redux/slice/main"
import { selectIsAllowProposeNewServiceMain, selectUser } from "../../redux/slice/auth"
import { setIsSearchVal } from "../../redux/slice/params"
import useWindowSize from "../../hooks/useWindowSize"
import ImportantEvents from "../ImportantEvents/ImportantEvents"
import MainLayoutLoader from "../LayoutLoaders/MainLayoutLoader"
import IconBtn from "../Assets/IconBtn/IconBtn"
import { QRMobIcon } from "../../icons/QRMobIcon"
import clsx from "clsx"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import { useParams } from "react-router-dom"
import BtnObject from "../Assets/BtnObject/BtnObject"
import styles from "./MainLayout.module.scss"
import QrScanModal from "../Modals/QrScanModal/QrScanModal"
import QrScanner from "qr-scanner"

const MainLayout = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { object_id } = useParams()

  const {
    data: categoriesData,
    refetch,
    isUninitialized: isCategoriesUninitialized,
  } = useGetCategoriesQuery(undefined, { skip: !!object_id })
  const [getServices] = useGetServicesMutation()

  const [getCategoriesByTechObj, { data: categoriesDataByTech, isUninitialized: isCategoriesTechUninitialized }] =
    useGetCategoriesByTechObjMutation()
  const { data: techObjData } = useGetTechObjectQuery(object_id as string, { skip: !object_id })

  const { serviceListLoading } = useAppSelector((state) => state.main)
  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const user = useAppSelector(selectUser)

  const { isDesktop } = useWindowSize()

  const [searchVal, setSearchVal] = useState<string | number>("")
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const currentLang = localStorage.getItem("i18nextLng") || "en"
  const [serviceList, setServiceList] = useState<any[]>([])
  const [isQrScanModal, setQrScanModal] = useState<boolean>(false)
  const [isCamera, setCamera] = useState<boolean>(false)

  useEffect(() => {
    dispatch(changeServiceListLoading(true))
    if (isCategoriesUninitialized && !object_id) return
    if (object_id) {
      getCategoriesByTechObj({ technical_object_id: object_id })
        .unwrap()
        .then((res) => {
          dispatch(updateServiceList(res ?? []))
        })
        .catch((err: any) => {
          console.error(err)
          dispatch(changeServiceListLoading(false))
        })
    } else {
      refetch()
    }
  }, [currentLang, object_id, isCategoriesUninitialized])

  useEffect(() => {
    if (isCategoriesUninitialized || !categoriesData) return
    dispatch(updateServiceList(categoriesData ?? []))
  }, [categoriesData, isCategoriesUninitialized])

  useEffect(() => {
    setIsSearchLoading(true)
    if (searchVal === "") setIsSearchLoading(false)

    dispatch(setIsSearchVal(Boolean(searchVal && searchVal !== "")))

    if (typeof searchVal === "string" && searchVal.trim().length > 0) {
      const timeoutId = setTimeout(() => {
        getServices({ keywords: searchVal, technicalObjects: object_id ? [object_id] : undefined })
          .unwrap()
          .then((res) => {
            setIsSearchLoading(false)
            setServiceList(res.data.aItems)
          })
          .catch((err) => console.warn(err))
      }, 500)

      return () => clearTimeout(timeoutId)
    }
  }, [searchVal])

  useEffect(() => {
    QrScanner.hasCamera().then((res) => setCamera(res))
  }, [])

  return serviceListLoading ? (
    isDesktop ? (
      <MainLayoutLoader />
    ) : (
      <>
        <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={serviceListLoading} />
        <OrdersLine isLoading={serviceListLoading} />
        <ServicesList />
      </>
    )
  ) : (
    <>
      {object_id && !searchVal && <TitleBack title={t("mainMenu")} className={styles.titleObj} />}
      {searchVal && (
        <TitleBack
          title={t("search")}
          link={object_id && `/object/${object_id}`}
          setSearchVal={setSearchVal}
          searchVal={searchVal}
          className={clsx(object_id && styles.titleObj)}
        />
      )}

      {techObjData?.id && <BtnObject txt={techObjData.name} type={"div"} mode={"white"} className={styles.techObj} />}

      <div
        className={clsx(
          styles["main-layout-search-block"],
          !isDesktop && styles["main-layout-search-block__added-padding"],
        )}
      >
        <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={serviceListLoading} />
        {isCamera && !isDesktop && (
          <IconBtn
            className={styles["main-layout-search-block__QR-btn"]}
            icon={<QRMobIcon />}
            onClick={() => setQrScanModal(true)}
          />
        )}
      </div>
      {!searchVal && !object_id && <ImportantEvents />}

      {searchVal ? (
        <SearchList list={serviceList} isSearchLoading={isSearchLoading} searchVal={searchVal} />
      ) : (
        <>
          {user?.id && !isDesktop && <OrdersLine />}
          <ServicesList />

          {!object_id && !isCategoriesUninitialized && !categoriesData?.length && <div>{t("emptyServices")}</div>}
          {object_id && !isCategoriesTechUninitialized && !categoriesDataByTech?.length && (
            <div>{t("emptyObject")}</div>
          )}
        </>
      )}

      {isAllowProposeNewServiceMain && <NoFindBtn isLoading={serviceListLoading} />}
      {isQrScanModal && <QrScanModal open={isQrScanModal} setOpen={setQrScanModal} />}
    </>
  )
}

export default MainLayout
